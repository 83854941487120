import styled, { css } from 'styled-components';
import { Navbar } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import media from 'styled-media-query';

const Menu = styled(Navbar)`
  background: #fff;
  font-size: 18px;
  transition: 0.2s ease-in-out;

  img {
    height: 90px;
    transition: 0.2s ease-in-out;
  }

  &.menu-scrolled {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    img {
      height: 45px;
    }
  }

  ${media.lessThan('small')`
    img {
      height: 45px;
    }
  `}
`;

const MenuHamburguer = styled(Navbar.Toggle)`
  border: none;
  padding-left: 0;
  padding-right: 6px;

  span {
    display: block;
    width: 35px;
    height: 5px;
    margin-bottom: 5px;
    position: relative;
    background: #186492;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    transform: rotate(-45deg) translate(-2px, -1px);

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-last-child(3) {
      transform-origin: 0% 100%;
      transform: rotate(45deg) translate(-2px, -1px);
    }
  }

  &.collapsed {
    span {
      transform: rotate(0) translate(0, 0);

      &:nth-last-child(2) {
        opacity: 1;
        transform: rotate(0deg) scale(1);
      }

      &:nth-last-child(3) {
        transform-origin: 0% 100%;
        transform: rotate(0) translate(0, 0);
      }
    }
  }
`;

const baseLink = css`
  color: #186492;
  display: block;
  font-weight: 600;
  margin: 0 1rem;
  padding: 0.5rem 0;
  border-bottom: solid 3px transparent;

  ${media.lessThan('small')`
    margin: 0;

    &:first-child{
      margin-top: 16px;
    }
  `}

  &:hover {
    color: #186492;
    text-decoration: none;
    border-bottom: solid 3px #0074bb;
  }
`;

const MenuLinkAnchor = styled(AnchorLink)`
  ${baseLink}
`;

const MenuLink = styled.a`
  ${baseLink}
`;

export { Menu, MenuLink, MenuLinkAnchor, MenuHamburguer };
