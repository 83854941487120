/* eslint-disable no-undef */
import React, { useState, useEffect, forwardRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import * as S from './styled';

const Menu = forwardRef((props, ref) => {
  const isHome = typeof window !== 'undefined' && window.location.pathname === '/';
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const changeScroll = () => {
      setScrollY(window.pageYOffset);
    };

    window.addEventListener('scroll', changeScroll);
    return () => {
      window.removeEventListener('scroll', changeScroll);
    };
  });

  return (
    <S.Menu className={scrollY > 126 && 'menu-scrolled'} expand="lg" fixed="top" ref={ref}>
      <Container>
        <Navbar.Brand href="/">
          <img src="/logo.png" alt="Logo" />
        </Navbar.Brand>
        <S.MenuHamburguer aria-controls="basic-navbar-nav">
          <span />
          <span />
          <span />
        </S.MenuHamburguer>
        <Navbar.Collapse className="justify-content-end">
          {isHome ? (
            <Nav>
              <a href="/" style={{ display: 'none' }}>
                {' '}
              </a>
              <S.MenuLinkAnchor href="#home" offset="126">
                Início
              </S.MenuLinkAnchor>
              <S.MenuLinkAnchor href="#specialties" offset="126">
                Serviços
              </S.MenuLinkAnchor>
              <S.MenuLinkAnchor href="#plans" offset="126">
                Planos de Saúde
              </S.MenuLinkAnchor>
              <S.MenuLinkAnchor href="#about" offset="126">
                Sobre nós
              </S.MenuLinkAnchor>
              <S.MenuLinkAnchor href="#units" offset="126">
                Unidades
              </S.MenuLinkAnchor>
              <S.MenuLinkAnchor href="#contacts" offset="126">
                Contato
              </S.MenuLinkAnchor>
            </Nav>
          ) : (
            <Nav>
              <S.MenuLink href="/">Voltar</S.MenuLink>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </S.Menu>
  );
});

export default Menu;
